/* eslint-disable max-statements */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { indiaLogo } from "./constants";
import useTypingAnimation from "../../../utils/helpers/use-typing-animation";
import { NUMBER } from "../../../constants/app-constants";
import { formatInputValue } from "../../mobile/car-registration-landing-banner-experiment/selector";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { Input } from "@cars24/turbo-web/lib/input";
import { InputForm } from "@cars24/turbo-web/lib/input-form";
import RegNoPrefix from "./images/reg-no-prefix.svg";

const MAX_LENGTH = 30;

const InputRegNo = (props) => {
    const {
        value: registrationNo,
        isPlaceholderTypingAnimation,
        inputRef,
        onChange,
        isError,
        errorMessage,
        onBlurCallback,
        onFocusCallback,
        isFromRegHomePage,
        autoCapitalize,
        showErrorOnFocus,
        maxLength = MAX_LENGTH,
        validator = () => { },
        disableOnChangeError = false,
        isRevamped = false,
        prefix,
        isNewVersionEnabled = false,
        inputPlaceholder
    } = props;
    const placeholder = useTypingAnimation("DL 01 AB12XX", NUMBER.TWO_HUNDRED, isPlaceholderTypingAnimation);
    const previousValueRef = useRef("");
    const [hasError, setHasError] = useState(false);

    const setCursor = (position) => {
        if (inputRef && inputRef.current) {
            const input = inputRef.current;
            if (input.setSelectionRange) {
                input.setSelectionRange(position, position);
            } else if (input.createTextRange) {
                const range = input.createTextRange();
                range.collapse(true);
                range.moveEnd("character", position);
                range.moveStart("character", position);
                range.select();
            }
        }
    };

    const handleInputChange = async (value, selectionStart) => {
        await yieldToMain();
        const isDeleting = value.length < previousValueRef.current.length;
        const sanitizedValue = value.replace(/[^a-z0-9]/gi, "").toUpperCase();
        const formattedInput = formatInputValue({ inputValue: sanitizedValue });
        onChange(formattedInput);
        previousValueRef.current = formattedInput;

        const cursorPosOffsets = [NUMBER.THREE, NUMBER.SIX];
        const cursorOffset = cursorPosOffsets.includes(selectionStart) ? NUMBER.ONE : NUMBER.ZERO;
        const newCursorPos = isDeleting ? selectionStart : selectionStart + cursorOffset;

        window.setTimeout(() => setCursor(newCursorPos), 0);
    };

    const handleOnChange = ({ target }) => {
        const { value, selectionStart } = target;
        if (!disableOnChangeError) {
            if (isFromRegHomePage) {
                if (!validator(value) && value) {
                    setHasError(false);
                }
            } else if (validator(value)) {
                setHasError(false);
            } else {
                setHasError(true);
            }
        }

        if (value.length <= maxLength) {
            handleInputChange(value, selectionStart);
        }
    };

    if (isNewVersionEnabled) {
        return (
            <InputForm
                allowClear={false}
                required={false}
                type="reg-no"
                label=""
                placeholder={inputPlaceholder || "DL 01 AB12XX"}
                value={registrationNo}
                isError={isError}
                prefix={<img width={"30px"} height={"30px"} src={RegNoPrefix} alt="Reg no" />}
                onChange={handleOnChange}
                transformValue={"uppercase"}
                customErrorMessage={errorMessage}
                onFocus={onFocusCallback}
                validator={validator}
            />
        );
    }

    if (isRevamped) {
        return (
            <Input
                customErrorMessage={errorMessage}
                type="text"
                prefix={prefix}
                onChange={handleOnChange}
                value={registrationNo}
                onBlur={onBlurCallback}
                onFocus={onFocusCallback}
                isError={isError}
                placeholder="DL 01 AB12XX"
                ref={inputRef}
            />
        );
    }

    return (
        <div styleName={`styles.inputShift styles.prefixImg`} >
            <div dangerouslySetInnerHTML={{ __html: indiaLogo }} />
            <input
                ref={inputRef}
                type={"text"}
                className="form-control"
                value={registrationNo}
                onChange={handleOnChange}
                placeholder={placeholder}
                onBlur={onBlurCallback}
                onFocus={onFocusCallback}
                autoCapitalize={autoCapitalize}
                maxLength={maxLength}
            />
            {
                (isError || showErrorOnFocus || hasError) &&
                <small styleName={"styles.error"}>{errorMessage}</small>
            }
        </div>
    );
};

InputRegNo.propTypes = {
    value: PropTypes.string,
    isPlaceholderTypingAnimation: PropTypes.bool,
    inputRef: PropTypes.any,
    onChange: PropTypes.func,
    isError: PropTypes.bool,
    errorMessage: PropTypes.string,
    onBlurCallback: PropTypes.func,
    onFocusCallback: PropTypes.func,
    customErrorText: PropTypes.string,
    isFromRegHomePage: PropTypes.bool,
    autoCapitalize: PropTypes.string,
    showErrorOnFocus: PropTypes.bool,
    maxLength: PropTypes.number,
    validator: PropTypes.func,
    disableOnChangeError: PropTypes.bool,
    isRevamped: PropTypes.bool,
    prefix: PropTypes.any,
    isNewVersionEnabled: PropTypes.bool,
    inputPlaceholder: PropTypes.string
};

export default InputRegNo;
