/* eslint-disable max-depth */
/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useCallback, startTransition } from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";
import Button from "../../shared/button";
import { STEP_NAMES, LEAD_FORM, ACTIVE_VARIANT_SCREEN, NUMBER, AB_TEST_VARIATIONS, HTTP_CODES } from "../../../constants/app-constants";
import { sendCustomGaEvents } from "../../../tracking";
import { FUNNEL_CONFIG } from "../../../utils/funnel/steps.mobile";
import { isValidRegistrationNo } from "../../../utils/helpers/is-valid-registration-no";
import { getDataByKey, formatInputValue, REGISTRATION_STEP_NAMES, responseOrder, STEPS_TO_IGNORE, getParsedLeadFormData } from "./selector";
import { C2B_LEAD_TYPES } from "../../../constants/seller-lead-types";
import RegNoPrefix from "./images/reg-no-prefix.svg";

import {
    invalidRegNumber as invalidRegNumberGA,
    regNumberEntered,
    carNoFocus,
    rtoStateSkipped,
    rtoCodeSkipped,
    repeatInspection,
    repeatUser,
    processHighlightRegUsed
} from "./tracking";
import ArrowIcon from "../../shared/arrow";
import IconSpinner from "../../shared/icon-spinner";
import InputRegNo from "../../shared/input-reg-no";
import { getActiveTab } from "../sell-onlie-car-evaluation/selector";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";
import { useHistory } from "react-router";
import InputText from "../../shared/input-text";
import emailValidator from "../../../utils/helpers/email-validator";
import { clearItem, getItem, setItem } from "../../../utils/helpers/storage-crud";
import { Button as DlsButton} from "@cars24/turbo-web/lib/button";
import { ALLIANCE_PAGE_REF_KEY, GS_TAGS, SELLER } from "../../../constants/seller-constant";
import { getPlatformHeaders } from "../../../utils/helpers/get-platform-headers";
import { getStepindex, getUpdatedLeadFormData } from "../lead-form/selector";
import disableScroll from "../../../utils/helpers/disable-scroll";
import EvVehicles from "../ev-vehicles";
import { variants } from "../../../constants/variant-constants";
import GsRepeatComponentWrapper from "../gs-repeat-bottomsheet-wrapper";

const TABS = [
    { name: "Brand", index: 1, key: "brand" },
    { name: "Year", index: 2, key: "year" },
    { name: "Model", index: 3, key: "model" },
    { name: "Variant", index: 4, key: "variant" },
    { name: "States", index: 5, key: "registrationState" }
];

const copyOfResponseOrder = [...responseOrder];
const { stepper, tabs, tabData } = FUNNEL_CONFIG[LEAD_FORM.DEFAULT];

const CarRegistrationLandingBannerExperiment = ({
    isLoggedIn,
    isNewHomePage = false,
    safetyBannerTopRef,
    safetyBannerTopFlag = false,
    title = "Enter your car number",
    text = "GET CAR PRICE",
    setRegistrationNoConnect,
    getVehicleDetailConnect,
    updateLeadDataConnect,
    saveLeadFormDataConnect,
    fetchYearsBasedOnMakeIdConnect,
    setStepConnect,
    // setFuelTypeConnect,
    // setTransmissionTypeConnect,
    setVariantActiveScreenConnect,
    setRTOConnect,
    setOwnerNameConnect,
    allStepFilledConnect,
    leadData,
    registrationNo,
    setUserCarRgistrationNoConnect,
    resetLeadFormDataConnect,
    setIsRegInputBoxActiveConnect,
    setUserCarRegistrationNoConnect,
    setAllDetailFetchedCorrectlyConnect,
    setAnsweredQuestionsConnect,
    setNameConnect,
    disableBtnOnInvalidInput = false,
    disableRouting = false,
    onSuccess = () => { },
    onError = () => { },
    customRouting = false,
    setHypothecationConnect,
    sellerOnFocus = () => { },
    sellerOnBlur = () => { },
    sellerOnClickCta = () => { },
    setDataFaliureConnect,
    handleClickCount = () => { },
    checkInspectedAppointmentExistenceConnect,
    isRepeatUser = false,
    widgetData,
    isCommercialCarAllowed = false,
    leadSource,
    sellMarketingExperiment,
    enableTypingAnimation = true,
    setIsRepeatInspectionConnect,
    userDropWidget,
    isSellMarketingRevamp = false,
    isAlliancReferraleOfferActive = false,
    isDlsCompliant = false,
    statsigId,
    setU2lGaFlagConnect = () => {},
    sendClevertapEventsConnect,
    secureToken,
    setShowModal,
    setIsClose,
    onCarRegPopupClose,
    isFirstFoldExpActive = false,
    ctaText,
    placeholder
}) => {
    const { data: userDropDetails } = userDropWidget || {};
    const { isUserDrop } = userDropDetails || {};
    const { isRepeatUserApplicable = false } = widgetData || {};
    const history = useHistory();
    const {
        ownerName,
        currentStep,
        model: carModel,
        year: carYear,
        token
    } = leadData;
    const isAlliancePage = history.location.pathname.split("/").includes("offer");

    const [registrationNumber, setRegistrationNumber] = useState("");
    const [isNotCarReg, setIsNotCarReg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCommercialVehicle, setIsCommercialVehicle] = useState(false);
    const [isErrorOnCtaClick, setIsErrorOnCtaClick] = useState(false);
    const inputRef = useRef(null);
    const [commercialVehiclePopup, setCommercialVehiclePopup] = useState(null);
    const [isDuplicateAppointment, setIsDuplicateAppointment] = useState(false);
    const [email, setEmail] = useState("");
    const [isBottomsheet, setIsBottomsheet] = useState(false);
    const [gsData, setGsData] = useState({});
    const [evBottomsheet, setEvBottomsheet] = useState(false);

    const isRegNoPresent = registrationNumber && !!registrationNumber.length;
    const showErrorOnFocus = !isRegNoPresent && isErrorOnCtaClick;
    const evData = {
        ev_model: carModel ? carModel.displayText : "",
        ev_make: carYear ? carYear.displayText : ""
    };

    const registrationNumberValidator = useCallback((v) => {
        return isValidRegistrationNo(v.replace(/\s/g, ""));
    }, []);

    useEffect(() => {
        if (registrationNo && registrationNumberValidator(registrationNo)) {
            if (!isUserDrop) {
                const regNo = formatInputValue({ inputValue: registrationNo });
                setRegistrationNumber(regNo || "");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [registrationNo, isUserDrop]);

    const handleOnChange = useCallback((sanitizedValue) => {
        startTransition(() => {
            setIsDuplicateAppointment(false);
            setRegistrationNumber(sanitizedValue);
            setIsNotCarReg(false);
        });
    }, []);

    const onFocusCallback = useCallback(async () => {
        await yieldToMain();
        startTransition(() => {
            setIsRegInputBoxActiveConnect(true);
            sellerOnFocus();
            sendCustomGaEvents(carNoFocus);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onBlurCallBack = useCallback(async () => {
        setIsErrorOnCtaClick(false);
        const isValid = registrationNumberValidator(registrationNumber);

        await yieldToMain();
        startTransition(() => {
            sellerOnBlur(isValid);
            setIsRegInputBoxActiveConnect(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line consistent-return
    const handleRouting = useCallback(async (isError = false, step = currentStep) => {
        if (customRouting) {
            return onError();
        }
        if (isError && disableRouting) {
            // setUserCarRegistrationNoConnect("");
            onError();
        } else {
            await yieldToMain();
            history.push(`/sell-online-evaluation/${step}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customRouting, disableRouting, currentStep, history]);

    const isValidProperties = useCallback((obj) => {
        if (obj) {
            for (const key in obj) {
                if (obj[key] === null || obj[key] === "" || obj[key] === NUMBER.ZERO) {
                    return false;
                }
            }
        } else {
            return false;
        }
        return true;
    }, []);

    useEffect(() => {
        if (!isRepeatUserApplicable) allStepFilledConnect(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line max-params
    const populateSetData = useCallback((stepname, data, leadDetails, resetValues) =>
        getUpdatedLeadFormData({ type: stepname, data, stepper, tabs, tabData, leadData: leadDetails, registrationNo, resetValues })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    , []);

    const setYearId = useCallback(async (carDetails) => {
        try {
            const { year, brand, regn_year: regYear } = carDetails;
            const selectedYear = (year && year.year) || regYear;
            const yearList = await fetchYearsBasedOnMakeIdConnect(brand.make_id);
            const yearObj = yearList.filter((data) => data.year === selectedYear);
            const yearId = yearObj && yearObj[0] && yearObj[0].year_id;
            if (yearId) return { ...carDetails, year: { year: selectedYear, year_id: yearId } };
            return carDetails;
        } catch (e) {
            return carDetails;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setCarEvaluationSteps = useCallback((key) => {
        let stepIndex = TABS.findIndex(tab => (tab.key.toLowerCase() === key));
        if (stepIndex !== -1) {
            if (stepIndex < NUMBER.FOUR) {
                stepIndex += NUMBER.ONE;
            }
            setStepConnect({ currentStep: TABS[stepIndex].key, tabs });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUpdateLeadForm = useCallback((leadFormData) => {
        const payload = {
            ...(statsigId && { client_id: statsigId }),
            data: leadFormData
        };

        updateLeadDataConnect(payload.data);
        if (statsigId) saveLeadFormDataConnect(payload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onCommercialVehPopupClose = useCallback(() => {
        setUserCarRegistrationNoConnect("");
        setRegistrationNoConnect("");
        setRegistrationNumber("");
        setIsCommercialVehicle(false);
        setOwnerNameConnect("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const breakAdrillaFlow = useCallback((response) => {
        setIsLoading(false);
        setAllDetailFetchedCorrectlyConnect(false);
        const leadFormData = getParsedLeadFormData(response);
        const activeTab = getActiveTab(tabs, leadFormData, STEP_NAMES.City);
        handleUpdateLeadForm({ ...leadFormData, currentStep: activeTab, currentStepIndex: getStepindex(activeTab) });
        if (response.rc_vh_class_desc !== "M-Cycle/Scooter(2WN)") {
            handleRouting(true, activeTab);
        } else {
            setIsNotCarReg(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleRouting]);

    const handleRegNoFlow = async (response, regNoTrimmed) => {
        let leadFormData = leadData;
        if (!isCommercialCarAllowed && response.isCommercial) {
            setIsLoading(false);
            setIsCommercialVehicle(true);
            return;
        }
        if (response && (response.fuelType === variants.Electric || (response.ds_details &&  response.ds_details[0].variant?.fuel_type === variants.Electric))) {
            setIsLoading(false);
            setEvBottomsheet(true);
            return;
        }
        await yieldToMain();
        resetLeadFormDataConnect(isRepeatUser);

        let alteredCarDetail = response;
        alteredCarDetail = Object.assign(...copyOfResponseOrder, alteredCarDetail);

        if (response && response.rc_owner_name) setOwnerNameConnect(response.rc_owner_name);
        if (response && response.states && response.states.state_id) {
            leadFormData = populateSetData(STEP_NAMES.State, getDataByKey(response, REGISTRATION_STEP_NAMES.STATES), leadFormData);
            startTransition(() => {
                sendCustomGaEvents(rtoStateSkipped);
            });
        }

        if (response && response.RTO && response.RTO.rto_detail_id) {
            setRTOConnect({ id: alteredCarDetail.RTO.rto_detail_id, displayText: alteredCarDetail.RTO.rto_code });
            leadFormData = populateSetData(STEP_NAMES.RTO, getDataByKey(response, REGISTRATION_STEP_NAMES.RTO), leadFormData, false);
            setIsLoading(false);
            startTransition(() => {
                sendCustomGaEvents(rtoCodeSkipped);
            });
        }

        if (alteredCarDetail.ds_details) {
            onSuccess(regNoTrimmed);
            setUserCarRegistrationNoConnect(regNoTrimmed);

            if (alteredCarDetail && alteredCarDetail.hypothecation !== null) setHypothecationConnect(alteredCarDetail.hypothecation);
            if (alteredCarDetail && alteredCarDetail.rc_owner_name) setNameConnect(alteredCarDetail.rc_owner_name);
            if (alteredCarDetail && alteredCarDetail.rc_owner_sr) {
                setAnsweredQuestionsConnect({
                    key: "owner_number",
                    value: Number(alteredCarDetail.rc_owner_sr) <= NUMBER.THREE ? `${alteredCarDetail.rc_owner_sr}` : "more_than_3"
                });
            }

            const { year: fetchedYear, brand: fetchedBrand, regn_year: regYear } = response;

            if (!fetchedBrand || (fetchedBrand && !fetchedBrand.make_id)) {
                setDataFaliureConnect(true);
            }

            if (fetchedBrand && fetchedBrand.make_id && !fetchedYear && regYear) {
                alteredCarDetail = await setYearId(alteredCarDetail);
            }
            for (const key in alteredCarDetail) {
                if (isValidProperties(alteredCarDetail[key], key)) {
                    if (key === REGISTRATION_STEP_NAMES.BRAND) {
                        leadFormData = populateSetData(STEP_NAMES.Brand, getDataByKey(response, REGISTRATION_STEP_NAMES.BRAND), leadFormData, false);
                        setCarEvaluationSteps(key);
                    }

                    if (key === REGISTRATION_STEP_NAMES.YEAR) {
                        leadFormData = populateSetData(STEP_NAMES.Year, getDataByKey(alteredCarDetail, REGISTRATION_STEP_NAMES.YEAR), leadFormData, false);
                        setCarEvaluationSteps(key);
                    }

                    if (key === REGISTRATION_STEP_NAMES.MODEL) {
                        leadFormData = populateSetData(STEP_NAMES.Model, getDataByKey(response, REGISTRATION_STEP_NAMES.MODEL), leadFormData, false);
                        setCarEvaluationSteps(key);
                    }

                    if (key === REGISTRATION_STEP_NAMES.DS_DETAIL) {
                        const variantResult = alteredCarDetail[key];
                        const defaultVariant = variantResult && variantResult[0];
                        if (variantResult.length > NUMBER.ONE || !defaultVariant.variant) {
                            // setFuelTypeConnect("");
                            // setTransmissionTypeConnect("");
                            if (response.fuelType) {
                                // setFuelTypeConnect(response.fuelType);
                                setVariantActiveScreenConnect(ACTIVE_VARIANT_SCREEN.TRANSMISSIONTYPE);
                            }
                            breakAdrillaFlow(alteredCarDetail);
                            return;
                        } else {
                            // setFuelTypeConnect(defaultVariant.variant.fuel_type);
                            leadFormData = populateSetData(STEP_NAMES.FuelType, getDataByKey(response, REGISTRATION_STEP_NAMES.FUEL_TYPE), leadFormData, false);
                            leadFormData = populateSetData(STEP_NAMES.Transmission, getDataByKey(response.ds_details[0], REGISTRATION_STEP_NAMES.TRANSMISSION), leadFormData, false);
                            setVariantActiveScreenConnect(ACTIVE_VARIANT_SCREEN.VARIANT);
                            leadFormData = populateSetData(STEP_NAMES.Variant, getDataByKey(response.ds_details[0], REGISTRATION_STEP_NAMES.VARIANT), leadFormData, false);
                            setCarEvaluationSteps(STEP_NAMES.State);

                        }
                        if (alteredCarDetail.rc_vh_class_desc === "M-Cycle/Scooter(2WN)") {
                            setIsNotCarReg(true);
                        } else if (response.states && response.RTO) {
                            allStepFilledConnect(true);
                            setIsLoading(false);
                            setAllDetailFetchedCorrectlyConnect(true);
                            handleUpdateLeadForm(leadFormData);
                            await yieldToMain();
                            history.push("/reg-number-details");
                            return;
                        }
                        setCarEvaluationSteps(STEP_NAMES.Variant);
                    }

                } else if (!STEPS_TO_IGNORE.includes(key)) {
                    breakAdrillaFlow(alteredCarDetail);
                    return;
                }
            }

        } else {
            setIsLoading(false);
            setDataFaliureConnect(true);
            await yieldToMain();
            history.push(`/sell-online-evaluation/${STEP_NAMES.Brand}/`);
        }
        handleUpdateLeadForm(leadFormData);
    };

    const handleInvalidRegNo = async () => {
        setIsNotCarReg(true);
        if (inputRef && inputRef.current && !isRegNoPresent) {
            inputRef.current.focus();
            handleClickCount(NUMBER.ONE);
        }
        startTransition(() => {
            sendCustomGaEvents({
                ...invalidRegNumberGA,
                eventLabel: registrationNumber
            });
            if (isRepeatUser) {
                sendCustomGaEvents({
                    ...repeatUser,
                    eventAction: "wrong_reg_entered",
                    eventLabel: registrationNumber
                });
            }
        });

    };

    const proceedToRegNoFlow = async (regNoTrimmed) => {
        setIsErrorOnCtaClick(true);
        await yieldToMain();
        startTransition(() => {
            sendCustomGaEvents({
                ...regNumberEntered,
                eventLabel: regNoTrimmed,
                ...(leadSource === C2B_LEAD_TYPES.SCRAP_CAR && { eventCategory: "scrap_homepage" })
            });
        });
        setHypothecationConnect(null);
        sellerOnClickCta(regNoTrimmed);
        setUserCarRgistrationNoConnect(regNoTrimmed);
        setRegistrationNoConnect(regNoTrimmed);

        getVehicleDetailConnect(regNoTrimmed, true, getPlatformHeaders(SELLER))
            .then((response) => {
                handleRegNoFlow(response, regNoTrimmed);
            })
            .catch(() => {
                setIsLoading(false);
                if (!disableRouting) resetLeadFormDataConnect(isRepeatUser);
                handleRouting(true);
            });
    };

    useEffect(() => {
        disableScroll((isBottomsheet), "hidden");
    }, [isBottomsheet]);

    const handleGsFlow = (response) => {
        setIsLoading(false);
        if (response?.leadGsStateDto) {
            setGsData(response.leadGsStateDto);
        }
        setIsBottomsheet(true);
        setShowModal(false);
        onCarRegPopupClose();
    };

    const handleAppointmentExistenceCheck = async () => {
        if (isLoading) return;
        if (sellMarketingExperiment === AB_TEST_VARIATIONS.VARIANT_A && !enableTypingAnimation) {
            sendCustomGaEvents(processHighlightRegUsed);
        }
        const isValid = registrationNumberValidator(registrationNumber);
        if (isValid) {
            if (isAlliancReferraleOfferActive && isAlliancePage) {
                if (emailValidator(email)) {
                    setItem(email, ALLIANCE_PAGE_REF_KEY);
                } else {
                    clearItem(ALLIANCE_PAGE_REF_KEY);
                }
            }
            setIsLoading(true);
            setU2lGaFlagConnect(false);
            const regNoTrimmed = (registrationNumber || "").replace(/\s/g, "");
            checkInspectedAppointmentExistenceConnect(regNoTrimmed, leadSource, secureToken)
                .then(async (response) => {
                    const experimentType = response.userJourneyAbExperiment;
                    const isRepeatInspection = response.isRepeatInspection;
                    const {validForGsLeadStop, gsCarStatus = ""} = response.leadGsStateDto || {};
                    setIsRepeatInspectionConnect(isRepeatInspection);
                    if (isRepeatInspection || experimentType === AB_TEST_VARIATIONS.CONTROL) {
                        startTransition(() => {
                            sendCustomGaEvents({
                                ...repeatInspection,
                                eventAction: `repeat_user_${experimentType ? experimentType.toLowerCase() : experimentType}`,
                                eventLabel: `${response.leadToken}`
                            });
                        });
                    }
                    if (validForGsLeadStop && gsCarStatus !== GS_TAGS.LC) {
                        handleGsFlow(response);
                    } else if (isRepeatInspection) {
                        handleRegNoFlow(response, regNoTrimmed);
                    } else {
                        proceedToRegNoFlow(regNoTrimmed);
                    }
                    // eslint-disable-next-line no-unused-vars
                }).catch((error) => {
                    const { status = "" } = error;
                    if (status === HTTP_CODES.RESOURCE_ALREADY_EXIST) {
                        setIsDuplicateAppointment(true);
                    }
                    setIsLoading(false);
                });

            startTransition(() => {
                if (isRepeatUser) {
                    sendCustomGaEvents({
                        ...repeatUser,
                        eventAction: "correct_reg_entered",
                        eventLabel: regNoTrimmed
                    });
                }
                if (isAlliancePage) {
                    sendCustomGaEvents({
                        ...regNumberEntered,
                        eventCategory: "alliance_homepage",
                        eventLabel: regNoTrimmed
                    });
                }
            });

        } else {
            handleInvalidRegNo();
        }
    };

    useEffect(() => {
        if (isAlliancReferraleOfferActive && isAlliancePage) {
            const data = getItem(ALLIANCE_PAGE_REF_KEY);
            if (data && emailValidator(data)) setEmail(data);
        }
    }, [isAlliancReferraleOfferActive, isAlliancePage]);

    useEffect(() => {
        if (isCommercialVehicle && commercialVehiclePopup === null) {
            import(/* webpackChunkName: "commercial-vehicle" */ "../commercial-vehicle-popup").then((comp) => {
                setCommercialVehiclePopup(
                    <comp.default
                        ownerName={ownerName}
                        // registrationNumber={registrationNumber}
                        onClose={onCommercialVehPopupClose}
                    />
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCommercialVehicle]);

    const renderCta = () => {
        if (isLoading) return <IconSpinner />;
        return (
            <React.Fragment>
                <ArrowIcon
                    orange={true}
                    rotateBy={-90}
                    onClick={handleAppointmentExistenceCheck}
                />
            </React.Fragment>
        );
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    return (
        <React.Fragment>
            {
                !isFirstFoldExpActive && !isSellMarketingRevamp && title && !isRepeatUser &&
                <div styleName={"styles.titleWrapper"}>
                    <label styleName={"styles.label"}>{title}</label>
                </div>
            }
            <div styleName={`${(isSellMarketingRevamp || isFirstFoldExpActive) ? "" : "styles.registrationNumber styles.formGroup"} ${isNotCarReg ? "styles.errorState" : ""} ${!isRepeatUser ? "styles.prefixImgWrapper" : ""}`}>
                <div styleName={`${isRepeatUser ? "styles.repeatUserWrapper" : ""}`}>
                    <InputRegNo
                        disableOnChangeError={true}
                        value={registrationNumber}
                        onChange={handleOnChange}
                        // validator={registrationNumberValidator}
                        onBlurCallback={onBlurCallBack}
                        onFocusCallback={onFocusCallback}
                        errorMessage={isDuplicateAppointment ? "Appointment already exists" : "Please enter a valid car number"}
                        isFromRegHomePage={!disableRouting}
                        isError={isDuplicateAppointment || isNotCarReg || (showErrorOnFocus && !isFirstFoldExpActive)}
                        autoCapitalize="characters"
                        inputRef={inputRef}
                        showErrorOnFocus={showErrorOnFocus}
                        isPlaceholderTypingAnimation={!isLoggedIn}
                        isRevamped={isSellMarketingRevamp}
                        isNewVersionEnabled={isFirstFoldExpActive}
                        inputPlaceholder={placeholder}
                        prefix={<img width={"41px"} height={"58px"} src={RegNoPrefix} alt="Reg no" />}
                    />
                    {
                        isRepeatUser &&
                        <div styleName={`styles.repeatUserCta ${isFirstFoldExpActive ? "styles.repeatUserCtaExp" : ""}`}>
                            {renderCta()}
                        </div>
                    }
                </div>
                {safetyBannerTopFlag && <span ref={safetyBannerTopRef} />}
                {
                    !isRepeatUser &&
                    <React.Fragment>
                        {
                            isAlliancReferraleOfferActive && isAlliancePage &&
                            <div styleName={"styles.emailWrapper"}>
                                <label styleName={"styles.label"}>Enter RA email address</label>
                                <InputText
                                    type="email"
                                    placeholder="Enter RA email address"
                                    value={email}
                                    id="email"
                                    validator={emailValidator}
                                    onChange={handleEmailChange}
                                    showErrorOnFocus={true}
                                    errorMessage="Please enter a valid email address"
                                />
                            </div>
                        }
                        <div styleName={`styles.buttonWrap ${!!(registrationNumber.length) && isNewHomePage ? "styles.newButtonWrap" : ""} `}>
                            { (isDlsCompliant || isFirstFoldExpActive) ?
                                <DlsButton
                                    label={isFirstFoldExpActive ? ctaText : "Get car price"}
                                    size="large"
                                    onClick={handleAppointmentExistenceCheck}
                                    disabled={disableBtnOnInvalidInput && !registrationNumberValidator(registrationNumber)}
                                    loading={isLoading}
                                />
                                :  <Button
                                    text={text}
                                    isLoading={isLoading}
                                    onClick={handleAppointmentExistenceCheck}
                                    disabled={disableBtnOnInvalidInput && !registrationNumberValidator(registrationNumber)}
                                /> }
                        </div>
                    </React.Fragment>
                }
            </div>
            {isCommercialVehicle && commercialVehiclePopup}

            {isBottomsheet && <GsRepeatComponentWrapper
                isBottomsheet={isBottomsheet}
                gsData={gsData}
                setIsBottomsheet={() => {
                    setIsClose(true);
                    setIsBottomsheet(false);
                }}
                gsRedirectHandler={() => proceedToRegNoFlow((registrationNumber || "").replace(/\s/g, ""))}
            />}

            {evBottomsheet  && (
                <EvVehicles
                    isBottomsheet={evBottomsheet} onClose={() => setEvBottomsheet(false)} history={history}
                    sendClevertapEventsConnect={sendClevertapEventsConnect}
                    event={evData}
                    token={token}
                    secureToken={secureToken}
                    isBackRequired={false}
                    isHome
                />
            )}
        </React.Fragment>
    );
};

CarRegistrationLandingBannerExperiment.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    registrationNo: PropTypes.string,
    leadData: PropTypes.object,
    isFromSellOnlineFlow: PropTypes.bool,
    setRegistrationNoConnect: PropTypes.func,
    getVehicleDetailConnect: PropTypes.func,
    updateLeadDataConnect: PropTypes.func,
    saveLeadFormDataConnect: PropTypes.func,
    fetchYearListConnect: PropTypes.func,
    setStepConnect: PropTypes.func,
    // setFuelTypeConnect: PropTypes.func,
    setOwnerNameConnect: PropTypes.func,
    // setTransmissionTypeConnect: PropTypes.func,
    setVariantActiveScreenConnect: PropTypes.func,
    history: PropTypes.object,
    setRTOConnect: PropTypes.func,
    activateLastStepConnect: PropTypes.func,
    allStepFilledConnect: PropTypes.func,
    setUserCarRgistrationNoConnect: PropTypes.func,
    resetLeadFormDataConnect: PropTypes.func,
    setIsRegInputBoxActiveConnect: PropTypes.func,
    fetchYearsBasedOnMakeIdConnect: PropTypes.func,
    setDsaVariantsConnect: PropTypes.func,
    setUserCarRegistrationNoConnect: PropTypes.func,
    setAllDetailFetchedCorrectlyConnect: PropTypes.func,
    setAnsweredQuestionsConnect: PropTypes.func,
    setNameConnect: PropTypes.func,
    disableBtnOnInvalidInput: PropTypes.bool,
    disableRouting: PropTypes.bool,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    safetyBannerTopRef: PropTypes.any,
    safetyBannerTopFlag: PropTypes.bool,
    customRouting: PropTypes.bool,
    setHypothecationConnect: PropTypes.func,
    isNewHomePage: PropTypes.bool,
    sellerOnFocus: PropTypes.func,
    sellerOnBlur: PropTypes.func,
    sellerOnClickCta: PropTypes.func,
    setDataFaliureConnect: PropTypes.func,
    handleClickCount: PropTypes.func,
    checkInspectedAppointmentExistenceConnect: PropTypes.func,
    isRepeatUser: PropTypes.bool,
    widgetData: PropTypes.object,
    isCommercialCarAllowed: PropTypes.bool,
    leadSource: PropTypes.string,
    sellMarketingExperiment: PropTypes.string,
    enableTypingAnimation: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    setIsRepeatInspectionConnect: PropTypes.func,
    userDropWidget: PropTypes.object,
    isSellMarketingRevamp: PropTypes.bool,
    isAlliancReferraleOfferActive: PropTypes.bool,
    isDlsCompliant: PropTypes.bool,
    statsigId: PropTypes.string,
    setU2lGaFlagConnect: PropTypes.func,
    sendClevertapEventsConnect: PropTypes.func,
    secureToken: PropTypes.string,
    setShowModal: PropTypes.func,
    setIsClose: PropTypes.func,
    onCarRegPopupClose: PropTypes.func,
    isFirstFoldExpActive: PropTypes.bool,
    ctaText: PropTypes.string,
    placeholder: PropTypes.string
};

export default CarRegistrationLandingBannerExperiment;
